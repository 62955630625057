import { KeyboardEventHandler, useState } from 'react';

import styled from '@emotion/styled';
import { useAuth } from '@opendoor/auth-fe';
import {
  ButtonLoadingAnimation,
  Icons,
  PressableBox,
  Text,
  TextButton,
} from '@opendoor/bricks-next';
import { Box, List, ListItem } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';
import Field from '@opendoor/cinderblocks/growth/novo/inputs/Field';
import { InputContainerElement } from '@opendoor/cinderblocks/growth/novo/inputs/Input';
import { useRouter } from 'next/router';

import { OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product } from '__generated__/athena';

import { gateManualAddressVerification } from '../../components/shared/NewSellerFlowAddressInput';
import { useAddressValidationContext } from '../../helpers/AddressValidationContext';

interface PredictionTerm {
  offset: number;
  value: string;
}
export interface AutoCompletePrediction {
  description: string;
  place_id?: string;
  addressToken?: string;
  types?: string[];
  source: string;
  terms?: PredictionTerm[];
}

export type NovoAddressInputProps = {
  id: string;
  placeholder?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  prediction: {
    predictions: AutoCompletePrediction[];
    setPredictions: (predictions: AutoCompletePrediction[]) => void;
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handlePredictionSelect: (prediction: AutoCompletePrediction) => void;
    listBoxId?: string;
  };
  inNewAddressEntryExperiment?: boolean;
  product?: OdProtosSellReceptionData_SellerInput_AnalyticsMetadata_Product;
};

// Override the field input container styles
// If in the future, we have address entry component in bricks-next, we should use it instead
const CustomFieldWrapper = styled.div<{ noValue: boolean }>`
  ${InputContainerElement} {
    outline: none;
    border: 1.5px solid ${novo.colors.warmgrey300};
    &:hover {
      outline: none;
    }
    &:focus-within {
      outline: none;
      border-color: ${novo.colors.white0};
      border-top: 1.5px solid ${novo.colors.brandBlue600};
      border-left: 1.5px solid ${novo.colors.brandBlue600};
      border-right: 1.5px solid ${novo.colors.brandBlue600};
      border-top-left-radius: ${novo.radii.roundedSmooth};
      border-top-right-radius: ${novo.radii.roundedSmooth};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      ${(props) =>
        props.noValue &&
        `
        border-bottom-left-radius: ${novo.radii.roundedSmooth};
        border-bottom-right-radius: ${novo.radii.roundedSmooth};
        border-bottom: 1.5px solid ${novo.colors.brandBlue600};
      `}
    }
  }
`;

const NovoListBox = ({
  children,
  listBoxId,
  inNewAddressEntryExperiment,
}: {
  children: React.ReactNode;
  listBoxId: string;
  inNewAddressEntryExperiment?: boolean;
}) => {
  return (
    <Box
      borderRadius={inNewAddressEntryExperiment ? undefined : 'roundedSmooth'}
      borderBottomRadius={inNewAddressEntryExperiment ? 'roundedSmooth' : undefined}
      overflow="scroll"
      maxHeight="450px"
      pt={inNewAddressEntryExperiment ? 2 : undefined}
      paddingTop={inNewAddressEntryExperiment ? undefined : '85px'}
      position="absolute"
      top={
        inNewAddressEntryExperiment
          ? ['-11px', null, '-19px', '-27px']
          : ['-66px', null, '-74px', '-82px']
      }
      w="100%"
      border={inNewAddressEntryExperiment ? '1.5px solid #0040E6' : undefined}
      backgroundColor={inNewAddressEntryExperiment ? 'white0' : undefined}
      boxShadow={inNewAddressEntryExperiment ? undefined : 'small'}
    >
      <List variant="text" role="listbox" aria-label="addresses" id={listBoxId}>
        {children}
      </List>
    </Box>
  );
};

const AutoCompletePredictions = ({
  predictions,
  selectedIndex,
  onPredictionSelect,
  listBoxId,
  inNewAddressEntryExperiment,
  setAddressValidationExperimentVariant,
}: {
  predictions: AutoCompletePrediction[];
  selectedIndex?: number;
  onPredictionSelect: (prediction: AutoCompletePrediction) => void;
  listBoxId: string;
  inNewAddressEntryExperiment?: boolean;
  setAddressValidationExperimentVariant: (variant: string | undefined) => void;
}) => {
  const router = useRouter();
  const { user } = useAuth();
  return (
    <NovoListBox listBoxId={listBoxId} inNewAddressEntryExperiment={inNewAddressEntryExperiment}>
      {predictions.map((prediction, index) => {
        const description = prediction.description;
        const place_id = prediction.place_id;
        const address_token = prediction.addressToken;

        if (!description) return null;

        return (
          <ListItem
            key={place_id || address_token}
            role="option"
            aria-selected={selectedIndex === index ? 'true' : undefined}
          >
            <PressableBox
              zIndex={2}
              justifyContent="flex-start"
              onMouseDown={(e) => e.preventDefault()}
              analyticsName=""
              animation="accelerateDecelerateFastest"
              aria-label="Address predictions"
              height="100%"
              width="100%"
              hoverStyle={{
                backgroundColor: '$backgroundStateHoverPrimary',
              }}
              onPress={() => onPredictionSelect(prediction)}
              pressStyle={{
                scale: 0.98,
              }}
              padding={10}
            >
              <Text
                typography="$bodyXlarge"
                tag="span"
                textAlign="left"
                $smallerThanMD={{
                  typography: '$bodyMedium',
                }}
                px={10}
                py={2}
              >
                {description}
              </Text>
            </PressableBox>
          </ListItem>
        );
      })}
      {predictions.length < 5 && inNewAddressEntryExperiment && (
        <TextButton
          zIndex={2}
          onMouseDown={(e) => e.preventDefault()}
          label="Enter address manually instead"
          analyticsName="cosmos-address-input-manual-entry"
          type="button"
          onPress={() => {
            gateManualAddressVerification({
              forceRedirect: true,
              customerUUID: user?.customerUuid,
              setAddressValidationExperimentVariant,
            }).then((result) => {
              if (result.shouldRedirect && result.destination) {
                router.push(result.destination);
              }
            });
          }}
          width={'100%'}
          variant="primary"
          size="medium"
          px={20}
          paddingTop={10}
          paddingBottom={20}
        />
      )}
    </NovoListBox>
  );
};

const NovoAddressInput: React.FC<NovoAddressInputProps> = ({
  id,
  placeholder,
  disabled,
  hideLabel,
  prediction,
  inNewAddressEntryExperiment,
}) => {
  const {
    predictions,
    setPredictions,
    searchTerm,
    onChange,
    handlePredictionSelect,
    listBoxId = 'cb1-listbox',
  } = prediction;
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const [isFocused, setIsFocused] = useState(false);
  const { isPredictionSelectLoading, setAddressValidationExperimentVariant } =
    useAddressValidationContext();

  const onKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLButtonElement> = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const newSelectedIndex =
        selectedIndex === undefined || selectedIndex === predictions.length - 1
          ? 0
          : selectedIndex + 1;
      setSelectedIndex(newSelectedIndex);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const newSelectedIndex =
        selectedIndex === undefined || selectedIndex === 0
          ? predictions.length - 1
          : selectedIndex - 1;
      setSelectedIndex(newSelectedIndex);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (selectedIndex !== undefined) handlePredictionSelect(predictions[selectedIndex]);
    } else if (e.key === 'Escape') {
      setPredictions([]);
      setSelectedIndex(undefined);
    } else {
      setSelectedIndex(undefined);
    }
  };

  // Check if the new focused element is outside the container
  const handleContainerBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setIsFocused(false);
    }
  };

  return (
    <Box
      className="od-address-input"
      onFocus={() => setIsFocused(true)}
      onBlur={handleContainerBlur}
    >
      <Box position={'relative'} zIndex={2} className="cta-input-form">
        {inNewAddressEntryExperiment ? (
          <CustomFieldWrapper noValue={searchTerm === ''}>
            <Field
              label={hideLabel ? '' : 'Enter your home address'}
              input={{
                autoComplete: 'off',
                placeholder,
                disabled,
                id: id,
                onChange: onChange,
                onKeyDown: onKeyDown,
                value: searchTerm ?? '',
                type: 'text',
                role: 'combobox',
                'aria-autocomplete': 'list',
                'aria-expanded': predictions.length > 0 ? 'true' : 'false',
                'aria-controls': listBoxId,
                suffix: !isPredictionSelectLoading ? (
                  isFocused ? (
                    <Icons.Search color="$contentAccentBrand" />
                  ) : (
                    <Icons.Search />
                  )
                ) : (
                  <Box position="relative" px={5}>
                    <ButtonLoadingAnimation variant="secondary" size="xsmall" />
                  </Box>
                ),
              }}
            />
          </CustomFieldWrapper>
        ) : (
          <Field
            label={hideLabel ? '' : 'Enter your home address'}
            input={{
              autoComplete: 'off',
              placeholder,
              disabled,
              id: id,
              onChange: onChange,
              onKeyDown: onKeyDown,
              value: searchTerm ?? '',
              type: 'text',
              role: 'combobox',
              'aria-autocomplete': 'list',
              'aria-expanded': predictions.length > 0 ? 'true' : 'false',
              'aria-controls': listBoxId,
            }}
          />
        )}
      </Box>
      <Box position="relative">
        {searchTerm !== '' && isFocused && (
          <AutoCompletePredictions
            predictions={predictions}
            selectedIndex={selectedIndex}
            onPredictionSelect={handlePredictionSelect}
            listBoxId={listBoxId}
            inNewAddressEntryExperiment={inNewAddressEntryExperiment}
            setAddressValidationExperimentVariant={setAddressValidationExperimentVariant}
          />
        )}
      </Box>
    </Box>
  );
};

export default NovoAddressInput;
